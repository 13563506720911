import React from 'react'
//import {useIntl} from "gatsby-plugin-intl"
import Layout from '../../components/layout'
import Header from '../../components/header'
import InfoLine from '../../components/infoline'
import {graphql} from 'gatsby'



const AdditiveFertigung = (props) => (
  <Layout>
    
    <Header 
      title='Materialien'
      text='Standard- und Spezialfilamente'
    />
    
    <div className='row'>
      <div className='col-12'>

        <p>Am Markt sind eine Vielzahl von unterschiedlichen Materialien für den FDM-Druck verfügbar. Von PLA bis faserverstärktes Polyamid 
            oder auch PEEK: Wir beraten Sie gerne. 
        </p>
        <p>
        
            Darüber hinaus können wir durch unsere Spezialcompoundierung auch Sondermaterialien herstellen: Mit Füllstoffen oder Fasern 
            hochgefüllte Compounds können zu Filamenten verarbeiten und dann gedruckt werden.

        </p>
      </div>

    </div>

    <InfoLine
      image={props.data.imageOne}
      title='Standard-Filamente'
      col='col-1'
      text='PLA, High heat-PLA, mit naturfasern gefülltes PLA-Filament
    '
    />

    <InfoLine
      image={props.data.imageOne}
      title='Compoundierung'
      col='col-1'
      text='Mit unserer speziellen Compoundiertechnik können wir hochgefüllte Compounds herstellen. Eine sehr gute Durchmischung ist 
    gewährleistet. So können wir Compounds für Ihre Anwendung herstellen'
    />

    <InfoLine
      image={props.data.imageTwo}
      title='Filamente'
      col='col-1'
    text='Andere Materialien wie etwas Metall können mit hilfe einer Materialkatusche dem Druckerzeugnis hinzugefügt werden.'
    />
    </Layout>
)


export default AdditiveFertigung;


//fluidImage is defined on imagePage
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "2.png" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "foto.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "2.png" }) {
      ...fluidImage
    }
  }
`